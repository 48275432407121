import React from "react";
import {
  Row,
  Button,
  Col,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Table,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import AlertModal from "components/Modals/AlertModal";
import useEditPrintScenario from "./useEditPrintScenario";
import { TextArea } from "components/TextAreas/TextArea";
import PuffLoader from "react-spinners/PuffLoader";
import { InvoiceType } from "utilities/enums/InvoiceType";

function EditPrintScenario(props) {
  const {
    categories,
    floorsWithTables,
    handleUpdateScenarioSubmit,
    updatePrintScenarioLoading,
    inputs,
    setInputs,
    handleInputChange,
    handleSelectAllClicked,
    openConfigurationIndex,
    handleAddConfiguration,
    handleRemoveGroup,
    handleFloorCheckboxChange,
    printerGroupCategoryClicked,
    handleAddGroup,
    toggleConfigurations,
    toggleReceiptGroups,
    openReceiptGroupIndex,
    handleTableFilterChange,
    handleRemoveConfiguration,
    activePrinters,
    handleConfigPrinterChange,
    handleConfigRecieptTypeChange,
    scenarioLoading,
    setDescription,
    cloud_cash_register_enabled,
  } = useEditPrintScenario(props);
  const { t } = useTranslation();

  let modalBody = (
    <>
      <Row>
        <Col xs="12">
          <Col xs="12" md="6" className="mt-0 p-0">
            <FormGroup className="mb-0">
              <label className="form-control-label" htmlFor="input-enabled">
                {t("Enabled")}
              </label>
            </FormGroup>
            <FormGroup>
              <label className="custom-toggle mr-1 mb--2">
                <input
                  name="active"
                  type="checkbox"
                  checked={inputs.active ?? false}
                  onChange={(e) => setInputs({ ...inputs, active: e.target.checked })}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off={t("No")}
                  data-label-on={t("Yes")}
                />
              </label>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                {t("Name")}
              </label>
              <Input
                className="form-control-alternative"
                id="name"
                name="name"
                placeholder={t("Name")}
                type="text"
                value={inputs.name ?? ""}
                onChange={handleInputChange}
              />
              <p className="text-danger">{inputs.errors.name}</p>
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="description">
                {t("Description")}
              </label>
              <TextArea
                className="w-100 form-control"
                name="description"
                value={inputs.description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t("Description")}
              />
            </FormGroup>
          </Col>
          <Col xs="12" className="mt-3 p-0">
            <Card className="card-plain mb-2">
              <CardHeader className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">{t("Printers_Settings")}</h5>
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  id="add-btn"
                  onClick={handleAddConfiguration}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">{t("Add")}</span>
                </Button>
              </CardHeader>
              <CardBody className="p-2">
                <div className="accordion configurations-accordion configurations-accordion">
                  {inputs.configurations?.map((configuration, index) => {
                    let isInternalConfiguration =
                      !configuration.receipt_type || configuration.receipt_type === "internal";
                    let configHeader = t("Setting") + " " + (index + 1);
                    if (configuration?.printer) {
                      configHeader = configuration.printer?.attributes?.name;
                      let configTypeText = isInternalConfiguration ? t("Internal") : t(configuration.receipt_type);
                      configHeader += ` - ${configTypeText}`;
                    }
                    return (
                      <Card className="card-plain mb-2" key={index}>
                        <CardHeader
                          className={`p-3`}
                          role="tab"
                          onClick={() => toggleConfigurations(index)}
                          aria-expanded={openConfigurationIndex === index}
                        >
                          <h5 className="mb-0">{configHeader}</h5>
                        </CardHeader>
                        <Collapse role="tabpanel" isOpen={openConfigurationIndex === index}>
                          <CardBody className="pb-0 p-3">
                            <FormGroup className="col-12 col-md-4 p-0">
                              <label className="form-control-label" htmlFor="name">
                                {t("Select_Printer")}
                              </label>
                              <Input
                                className="form-control-alternative"
                                id={`select-printer-${index}`}
                                name={`select-printer-${index}`}
                                placeholder={t("Select_Printer")}
                                type="select"
                                bsSize="sm"
                                value={configuration.printer?.id ?? ""}
                                onChange={(e) => {
                                  handleConfigPrinterChange(e, configuration.id);
                                }}
                              >
                                <option value="">None</option>
                                {activePrinters.map((activePrinter) => {
                                  return (
                                    <option key={activePrinter.id} value={activePrinter.id}>
                                      {activePrinter.attributes.name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                            {cloud_cash_register_enabled && (
                              <FormGroup className="col-12 col-md-4 p-0">
                                <label className="form-control-label" htmlFor="name">
                                  {t("Select_Receipt_Type")}
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id={`select-receipt-type-${index}`}
                                  name={`select-receipt-type-${index}`}
                                  placeholder={t("Select_Receipt_Type")}
                                  type="select"
                                  bsSize="sm"
                                  value={configuration.receipt_type ?? "internal"}
                                  onChange={(e) => {
                                    handleConfigRecieptTypeChange(e, configuration.id);
                                  }}
                                >
                                  <option value="internal">{t("Internal")}</option>
                                  <option value="open_table">{t("OpenTable")}</option>
                                  <option value="retail">{t("GR_Receipt")}</option>
                                  <option value="wholesale">{t(InvoiceType.Wholesale)}</option>
                                </Input>
                              </FormGroup>
                            )}
                            {isInternalConfiguration && (
                              <FormGroup className="ml-1 mt-4 mb-3">
                                <div className="custom-control custom-checkbox d-inline">
                                  <input
                                    className="custom-control-input"
                                    type="checkbox"
                                    name={`hide_total-${index}`}
                                    id={`hide_total-${index}`}
                                    onChange={(e) => {
                                      setInputs((prev) => ({
                                        ...prev,
                                        configurations: prev.configurations.map((config) => {
                                          if (config.id === configuration.id) {
                                            return { ...config, hide_total: e.target.checked };
                                          }
                                          return config;
                                        }),
                                      }));
                                    }}
                                    checked={configuration.hide_total}
                                  />
                                  <label className="custom-control-label" htmlFor={`hide_total-${index}`}>
                                    {t(`Hide_total_on_receipt`)}
                                  </label>
                                </div>
                              </FormGroup>
                            )}
                            <Card className="card-plain mb-2">
                              <CardHeader className="d-flex justify-content-between align-items-center non-collapsible-header pl-3">
                                <h5 className="mb-0">{t("Internal_receipt_settings")}</h5>
                                <Button
                                  className="btn-round btn-icon"
                                  color="primary"
                                  id="add-btn"
                                  onClick={() => handleAddGroup(configuration.id)}
                                  size="sm"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-plus" />
                                  </span>
                                  <span className="btn-inner--text">{t("Add")}</span>
                                </Button>
                              </CardHeader>
                              <CardBody className="p-2">
                                <div className="accordion printer-groups-accordion permissions-accordion">
                                  {configuration.receiptGroups?.map((group, groupIndex) => {
                                    return (
                                      <Card className="card-plain mb-2" key={groupIndex}>
                                        <CardHeader
                                          className={`p-3`}
                                          role="tab"
                                          onClick={() => toggleReceiptGroups(groupIndex)}
                                          aria-expanded={openReceiptGroupIndex === groupIndex}
                                        >
                                          <h5 className="mb-0">{`${t("Internal_receipt_setting")} ${
                                            groupIndex + 1
                                          }`}</h5>
                                        </CardHeader>

                                        <Collapse role="tabpanel" isOpen={openReceiptGroupIndex === groupIndex}>
                                          <CardBody className="pt-0 pl-2 pr-2 pb-0">
                                            <FormGroup className="ml-1 mt-4 mb-3">
                                              <div className="custom-control custom-checkbox d-inline">
                                                <input
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  name={`table_filters_enabled-${groupIndex}`}
                                                  id={`config-${configuration.id}-table_filters_enabled-${groupIndex}`}
                                                  onChange={(e) =>
                                                    handleTableFilterChange(e, configuration.id, groupIndex)
                                                  }
                                                  checked={group.table_filters_enabled}
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={`config-${configuration.id}-table_filters_enabled-${groupIndex}`}
                                                >
                                                  {t(`Table_filter_msg`)}
                                                </label>
                                              </div>
                                            </FormGroup>
                                            {group.table_filters_enabled && (
                                              <Row>
                                                <Col xs="12" className="mt--2">
                                                  <FormGroup className="ml-1 mb-0">
                                                    <ListGroup>
                                                      {floorsWithTables.map((floor) => (
                                                        <ListGroupItem key={floor.id} className="p-2 d-flex">
                                                          <div className="custom-control custom-checkbox d-inline w-100">
                                                            <input
                                                              className="custom-control-input"
                                                              id={`config-${configuration.id}-floor-${floor.id}-group-${groupIndex}`}
                                                              type="checkbox"
                                                              onChange={(e) =>
                                                                handleFloorCheckboxChange(
                                                                  e,
                                                                  configuration.id,
                                                                  groupIndex,
                                                                  floor
                                                                )
                                                              }
                                                              checked={floor.attributes.tables.data.every((table) =>
                                                                group.table_filters?.includes(table.id)
                                                              )}
                                                            />
                                                            <label
                                                              className="custom-control-label w-100"
                                                              htmlFor={`config-${configuration.id}-floor-${floor.id}-group-${groupIndex}`}
                                                            >
                                                              {floor.attributes.name}
                                                            </label>
                                                          </div>
                                                        </ListGroupItem>
                                                      ))}
                                                    </ListGroup>
                                                  </FormGroup>
                                                </Col>
                                              </Row>
                                            )}
                                            {categories && isInternalConfiguration && (
                                              <Table className="group-categories-table">
                                                <thead>
                                                  <tr
                                                    className="hoverable"
                                                    onClick={() => handleSelectAllClicked(configuration.id, group)}
                                                  >
                                                    <th>{t("Select_categories")}</th>
                                                    <th className="d-flex justify-content-end">
                                                      <div className="custom-control custom-checkbox">
                                                        <input
                                                          className="custom-control-input"
                                                          id={`config-${configuration.id}-group-${groupIndex}-selectAll`}
                                                          type="checkbox"
                                                          onChange={() => {}}
                                                          checked={group?.categories?.length === categories.length}
                                                        />
                                                        <label
                                                          className="custom-control-label"
                                                          htmlFor={`config-${configuration.id}-group-${groupIndex}-selectAll`}
                                                        ></label>
                                                      </div>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {categories.map((category) => (
                                                    <tr
                                                      className="hoverable"
                                                      key={category.id}
                                                      onClick={(e) => {
                                                        printerGroupCategoryClicked(configuration.id, group, category);
                                                      }}
                                                    >
                                                      <td>{category.name}</td>
                                                      <td className="d-flex justify-content-end">
                                                        <div className="custom-control custom-checkbox">
                                                          <input
                                                            className="custom-control-input"
                                                            id={`config-${configuration.id}-group-${groupIndex}-category-${category.id}`}
                                                            type="checkbox"
                                                            onChange={(e) => {}}
                                                            checked={group.categories.includes(category.id)}
                                                          />
                                                          <label
                                                            className="custom-control-label"
                                                            htmlFor={`config-${configuration.id}-group-${groupIndex}-category-${category.id}`}
                                                          ></label>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </Table>
                                            )}
                                            <div className="card-footer w-100 d-flex justify-content-end pb-0 pt-3 pr-3">
                                              <p
                                                className="hoverable delete-group-btn font-weight-bold ml-1 mb-2 text-danger"
                                                onClick={() => handleRemoveGroup(configuration.id, group)}
                                              >
                                                {t("Delete")}
                                              </p>
                                            </div>
                                          </CardBody>
                                        </Collapse>
                                      </Card>
                                    );
                                  })}
                                </div>
                              </CardBody>
                            </Card>
                            <div className="card-footer w-100 d-flex justify-content-end pb-0 pt-3 pr-3">
                              <p
                                className="hoverable delete-group-btn font-weight-bold ml-1 mb-0 text-danger"
                                onClick={() => handleRemoveConfiguration(configuration.id)}
                              >
                                {t("Delete")}
                              </p>
                            </div>
                          </CardBody>
                        </Collapse>
                      </Card>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Col>
      </Row>
    </>
  );

  if (scenarioLoading) {
    modalBody = (
      <div className="w-100 d-flex justify-content-center">
        <PuffLoader
          loading={scenarioLoading}
          color="#d9d9d9"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return (
    <>
      <AlertModal
        size="lg"
        isOpen={props.isOpen}
        title={t("Edit_Scenario")}
        onCancel={props.onCancel}
        okText={t("Save")}
        cancelText={t("Cancel")}
        onOk={handleUpdateScenarioSubmit}
        okDisabled={updatePrintScenarioLoading}
        body={modalBody}
      ></AlertModal>
    </>
  );
}

export default EditPrintScenario;

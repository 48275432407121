import React from "react";
import { useAddPrintScenarioMutation } from "api/printScenariosSlice";
import { useGetCategoriesWithProductsQuery } from "api/categoriesSlice";
import { useGetFloorsWithTablesQuery } from "api/floorsSlice";
import { useGetPrintersQuery } from "api/printerSlice";
import { useSelector } from "react-redux";

const useNewPrintScenario = (props) => {
  const { data: categories } = useGetCategoriesWithProductsQuery();
  const { data: floorsWithTables } = useGetFloorsWithTablesQuery();
  const { data: printers } = useGetPrintersQuery();

  const [inputs, setInputs] = React.useState({
    name: "",
    description: "",
    active: false,
    configurations: [],
    errors: {},
  });
  const [addPrintScenario, { isLoading: addPrintScenarioLoading }] = useAddPrintScenarioMutation();
  const [openConfigurationIndex, setOpenConfigurationIndex] = React.useState("");
  const [openReceiptGroupIndex, setOpenReceiptGroupIndex] = React.useState("");
  const { cloud_cash_register_enabled } = useSelector((state) => state.settings);
  function clearInputs() {
    setInputs({
      name: "",
      active: false,
      configurations: [],
      errors: {},
    });
  }

  function setDescription(description) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        description: description,
      };
    });
  }

  function handleInputChange(event) {
    setInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  async function handleNewScenarioSubmit() {
    await addPrintScenario(inputs);
    clearInputs();
    props?.onCancel();
  }

  function handleSelectAllClicked(configurationId, group) {
    setInputs((prev) => ({
      ...prev,
      configurations: prev.configurations.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;
        return {
          ...configuration,
          receiptGroups: configuration.receiptGroups.map((receiptGroup) => {
            if (receiptGroup.id !== group.id) return receiptGroup;

            return {
              ...receiptGroup,
              categories:
                receiptGroup.categories.length === categories.length ? [] : categories.map((category) => category.id),
            };
          }),
        };
      }),
    }));
  }

  function printerGroupCategoryClicked(configurationId, group, category) {
    setInputs((prev) => ({
      ...prev,
      configurations: prev.configurations.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;

        return {
          ...configuration,
          receiptGroups: configuration.receiptGroups.map((receiptGroup) => {
            if (receiptGroup.id !== group.id) return receiptGroup;

            let inputChecked = receiptGroup.categories.find((cat) => cat === category.id);
            if (!inputChecked) {
              return {
                ...receiptGroup,
                categories: [...receiptGroup.categories, category.id],
              };
            } else {
              return {
                ...receiptGroup,
                categories: receiptGroup.categories.filter((id) => id !== category.id),
              };
            }
          }),
        };
      }),
    }));
  }

  function handleAddConfiguration() {
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: [
        ...prevInputs.configurations,
        {
          id: prevInputs.configurations.length,
          printer: null,
          receiptGroups: [],
          receipt_type: "internal",
          hide_total: false,
        },
      ],
    }));
  }
  function handleAddGroup(configurationId) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations.map((configuration) => {
        if (configurationId === configuration.id) {
          return {
            ...configuration,
            receiptGroups: [
              ...configuration.receiptGroups,
              {
                id: configuration.receiptGroups.length,
                categories: [],
                table_filters: [],
                table_filters_enabled: false,
              },
            ],
          };
        } else {
          return configuration;
        }
      }),
    }));
  }

  function handleFloorCheckboxChange(e, configurationId, groupIndx, floor) {
    const group = inputs.configurations.find((config) => config.id === configurationId)?.receiptGroups?.[groupIndx];
    const isChecked =
      e.target.checked && !floor.attributes.tables.data.every((table) => group.table_filters.includes(table.id));
    const tableIds = floor.attributes.tables.data.map((table) => table.id);

    if (isChecked) {
      setInputs((prev) => ({
        ...prev,
        configurations: prev.configurations.map((configuration) => {
          if (configuration.id !== configurationId) return configuration;
          return {
            ...configuration,
            receiptGroups: [
              ...configuration.receiptGroups.slice(0, groupIndx),
              {
                ...group,
                table_filters: [...group.table_filters, ...tableIds],
              },
              ...configuration.receiptGroups.slice(groupIndx + 1),
            ],
          };
        }),
      }));
    } else {
      setInputs((prev) => ({
        ...prev,
        configurations: prev.configurations.map((configuration) => {
          if (configuration.id !== configurationId) return configuration;
          return {
            ...configuration,
            receiptGroups: [
              ...configuration.receiptGroups.slice(0, groupIndx),
              {
                ...group,
                table_filters: group.table_filters.filter((id) => !tableIds.includes(id)),
              },
              ...configuration.receiptGroups.slice(groupIndx + 1),
            ],
          };
        }),
      }));
    }
  }

  function handleTableFilterChange(e, configurationId, groupIndex) {
    setInputs((prev) => ({
      ...prev,
      configurations: prev.configurations.map((configuration) => {
        if (configurationId !== configuration.id) return configuration;

        return {
          ...configuration,
          receiptGroups: configuration.receiptGroups.map((receiptGroup, indx) => {
            if (indx !== groupIndex) return receiptGroup;

            return {
              ...receiptGroup,
              table_filters_enabled: e.target.checked,
            };
          }),
        };
      }),
    }));
  }

  function handleRemoveGroup(configurationId, group) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;
        return {
          ...configuration,
          receiptGroups: configuration.receiptGroups.filter((currentGroup) => currentGroup.id !== group.id),
        };
      }),
    }));
  }
  function handleRemoveConfiguration(configurationId) {
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations.filter((configuration) => configuration.id !== configurationId),
    }));
  }

  function handleConfigRecieptTypeChange(e, configurationId) {
    let selectedReceiptType = e.target.value;
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;

        return {
          ...configuration,
          receipt_type: selectedReceiptType,
        };
      }),
    }));
  }

  function handleConfigPrinterChange(e, configurationId) {
    let selectedPrinterId = e.target.value;
    let selectedPrinter = printers.find((printer) => printer.id?.toString() === selectedPrinterId);
    setInputs((prevInputs) => ({
      ...prevInputs,
      configurations: prevInputs.configurations.map((configuration) => {
        if (configuration.id !== configurationId) return configuration;

        return {
          ...configuration,
          printer: selectedPrinter,
        };
      }),
    }));
  }

  function toggleConfigurations(id) {
    if (openConfigurationIndex === id) {
      setOpenConfigurationIndex();
    } else {
      setOpenConfigurationIndex(id);
    }
  }
  function toggleReceiptGroups(id) {
    if (openReceiptGroupIndex === id) {
      setOpenReceiptGroupIndex();
    } else {
      setOpenReceiptGroupIndex(id);
    }
  }

  return {
    handleNewScenarioSubmit,
    addPrintScenarioLoading,
    inputs,
    setInputs,
    handleInputChange,
    handleSelectAllClicked,
    categories,
    printerGroupCategoryClicked,
    floorsWithTables,
    handleAddConfiguration,
    toggleReceiptGroups,
    openReceiptGroupIndex,
    openConfigurationIndex,
    toggleConfigurations,
    handleAddGroup,
    handleFloorCheckboxChange,
    handleTableFilterChange,
    handleRemoveGroup,
    handleRemoveConfiguration,
    printers,
    handleConfigPrinterChange,
    handleConfigRecieptTypeChange,
    setDescription,
    cloud_cash_register_enabled,
  };
};

export default useNewPrintScenario;

import React, { useState, useEffect, useReducer, useMemo } from "react";
import { Row, Col, FormGroup, Button, Input, Alert, Card, CardHeader, Collapse, CardBody } from "reactstrap";
import parse from "html-react-parser";
import {
  useGetOrderQuery,
  useLazyResubmitOrderQuery,
  useReprintOrderMutation,
  useUpdateOrderStatusMutation,
  useOrdersBulkUpdateMutation,
  apiOrders,
} from "api/ordersSlice";
import Table from "components/Tables/BaseTable";
import { formatCurrency, mergeToCommaSepString, formatDateCustomFormat, capitalizeFirstLetter } from "utilities/utils";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { OrderStatus, getOrderStatus } from "utilities/enums/OrderStatus";
import { CANCELLATION_REASON_GIFT, RECEIPT_TYPES } from "utilities/constants";
import CancellationReasonModal from "components/Modals/CancellationReasonModal";
import GiftConfirmationModal from "components/Modals/GiftConfirmationModal";
import { broadCastOrdersChangeWS, subscribeToOrdersChangeWS } from "config/websocket";
import { TagTypes } from "utilities/enums/TagTypes";
import AlertModal from "components/Modals/AlertModal";
import { selectCurrentOrganisation, selectCurrentRole } from "auth/authSlice";
import { useUpdateProductMutation } from "api/productsSlice";
import usePayItems from "views/pages/tables/PayItems/usePayItems";
import { InvoiceType } from "utilities/enums/InvoiceType";
import { selectCurrentUser, selectCurrentUserRules } from "auth/authSlice";
import ROUTES_OBJ from "utilities/enums/Routes";
import SearchVatButton from "components/Buttons/SearchVatButton";
import { generateUUID } from "utilities/utils";
import { PaymentMethods } from "utilities/enums/PaymentMethods";
import CustomerSearch from "components/Modals/CustomerSearch";

export default function ViewOrder(props) {
  const { orderId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { username: user } = useSelector(selectCurrentUser);
  const user_rules = useSelector(selectCurrentUserRules);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { locale } = useSelector((state) => state.locale);
  const [resubmitOrder, { isLoading: resubmitOrderLoading, isError: resubmitOrderError }] = useLazyResubmitOrderQuery();
  const { data: order, isFetching: isOrderFetching } = useGetOrderQuery(orderId);
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const cancelGiftRuleEnabled = user_rules.find(
    (rule) => rule.name === `Route:${ROUTES_OBJ.TABLE_ACTIONS_CANCEL_GIFT.path}`
  )?.value;
  const {
    discounts_enabled,
    cloud_cash_register_enabled,
    helper_wholesale_receipts_enabled,
    manual_cloud_cash_register_enabled,
    pos_integration_enabled,
    wholesale_enabled,
  } = useSelector((state) => state.settings);
  const organisationId = useSelector(selectCurrentOrganisation);
  const [giftModalOpen, setGiftModalOpen] = useState(false);
  const [customerAccordionOpen, setCustomerAccordionOpen] = useState(false);
  const [submitInvoiceClicked, setSubmitInvoiceClicked] = useState(false);
  const [updateOrderStatus, { isLoading: updateOrderStatusLoading }] = useUpdateOrderStatusMutation();
  const [reprintOrder, { isLoading: reprintOrderLoading }] = useReprintOrderMutation();
  const [updateProduct] = useUpdateProductMutation();
  const { handleInventory, checkInventory } = usePayItems();
  const [reprint_type, setReprintType] = useState(RECEIPT_TYPES.internal);
  const [ordersBulkUpdate, { isLoading: bulkUpdateLoading }] = useOrdersBulkUpdateMutation();
  const [submitInvoiceType, setSubmitInvoiceType] = useState("");
  const [customerInputs, setCustomerInputs] = useState({
    name: "",
    vat_number: "",
    doy: "",
    gemi_number: "",
    address: "",
    city: "",
    postcode: "",
    phone_number: "",
    errors: {},
  });
  const currentUserRole = useSelector(selectCurrentRole);

  const orderDetails = order?.orderDetails.map((productDetails) => {
    const product = productDetails.product.data;
    const variants = productDetails.variant.map((variant) => {
      if (variant.quantity && variant.quantity > 1) {
        return `x${variant.quantity} ${variant.variant_option.data.attributes.label}`;
      }
      return variant.variant_option.data.attributes.label;
    });

    return {
      id: product.id,
      name: product.attributes.name,
      quantity: productDetails.quantity,
      price: formatCurrency(productDetails.price),
      variants: mergeToCommaSepString(variants),
      notes: productDetails.notes,
    };
  });

  let orderStatus = getOrderStatus(order?.status?.toLowerCase(), order?.cancellationReason);

  function refreshComponent() {
    dispatch(apiOrders.util.invalidateTags([TagTypes.Orders]));
    forceUpdate();
  }

  useEffect(() => {
    subscribeToOrdersChangeWS(organisationId, refreshComponent);
  }, []);
  useEffect(() => {
    setSubmitInvoiceClicked(false);
  }, [orderId]);

  /**
   * If there is a cancellation reason, and it is not Gift, we return it
   * If the cancellation reason is Gift, we don't return it because we have replaced the order status with Gift for ease of use
   */
  function getCancellationReason(order) {
    let cancellationReason = order?.cancellationReason;
    return cancellationReason && cancellationReason !== CANCELLATION_REASON_GIFT ? cancellationReason : null;
  }
  const canReturnInventory = useMemo(() => {
    return checkInventory(order?.orderDetails);
  }, [order?.orderDetails]);

  function cancelOrder(reason = null, return_inventory = false) {
    const UUID = generateUUID(user, organisationId);
    // we pass updateProduct so the function can be totally independent from the component
    handleInventory(return_inventory, order.orderDetails, updateProduct);
    if (order?.status?.toLowerCase() === OrderStatus.Pending) {
      let data = {
        orders: [order.id],
        resultStatus: OrderStatus.Cancelled,
        cancellationReason: reason,
        submittedBy: user,
        organisationId: organisationId,
        uuid: UUID,
      };
      ordersBulkUpdate(data).then((response) => {
        setCancellationModalOpen(false);
        broadCastOrdersChangeWS(organisationId);
      });
    } else {
      updateOrderStatus({
        orderId: order?.id,
        submittedBy: user,
        status: OrderStatus.Cancelled,
        cancellationReason: reason,
      }).then(() => {
        broadCastOrdersChangeWS(organisationId);
        setCancellationModalOpen(false);
      });
    }
  }

  function giftOrder(notes = null) {
    const UUID = generateUUID(user, organisationId);

    if (order?.status?.toLowerCase() === OrderStatus.Pending) {
      let data = {
        orders: [order.id],
        resultStatus: OrderStatus.Cancelled,
        cancellationReason: CANCELLATION_REASON_GIFT,
        submittedBy: user,
        organisationId: organisationId,
        uuid: UUID,
        notes: notes,
      };
      ordersBulkUpdate(data).then((response) => {
        setGiftModalOpen(false);
        broadCastOrdersChangeWS(organisationId);
      });
    } else {
      updateOrderStatus({
        orderId: order?.id,
        status: OrderStatus.Cancelled,
        submittedBy: user,
        cancellationReason: CANCELLATION_REASON_GIFT,
        notes: notes,
      }).then(() => {
        broadCastOrdersChangeWS(organisationId);
        setGiftModalOpen(false);
      });
    }
  }

  let productsTableCols = [
    { label: "id", hidden: true },
    { label: "name" },
    { label: "quantity" },
    { label: "price" },
    { label: "variants" },
    { label: "notes" },
  ];

  let submitRetailInvoiceDisabled = !(
    cloud_cash_register_enabled &&
    (order?.status?.toLowerCase() === OrderStatus.Payed || order?.status?.toLowerCase() === OrderStatus.Pending) &&
    !(order?.receipt_id > 0 || order?.mydata_status === "sent" || resubmitOrderLoading)
  );

  let helperwholesaleInvoiceEnabled =
    helper_wholesale_receipts_enabled &&
    submitInvoiceType === RECEIPT_TYPES.helperWholesale &&
    (order?.receipt_id > 0 || order?.status?.toLowerCase() !== OrderStatus.Payed);

  let wholesaleInvoiceEnabled =
    cloud_cash_register_enabled && wholesale_enabled && order?.status?.toLowerCase() !== OrderStatus.Cancelled;

  function customerFieldsValidator() {
    let isValid = true;
    if (customerInputs.name.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: t("Name_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, name: "" },
        };
      });
    }
    if (customerInputs.vat_number.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, vat_number: t("Vat_number_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, vat_number: "" },
        };
      });
    }

    if (customerInputs.doy.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, doy: t("Doy_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, doy: "" },
        };
      });
    }

    if (customerInputs.address.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, address: t("Address_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, address: "" },
        };
      });
    }

    if (customerInputs.city.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, city: t("City_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, city: "" },
        };
      });
    }

    if (customerInputs.postcode.length === 0) {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, postcode: t("Postcode_invalid_msg") },
        };
      });
      isValid = false;
    } else {
      setCustomerInputs((prevInputs) => {
        return {
          ...prevInputs,
          errors: { ...prevInputs.errors, postcode: "" },
        };
      });
    }

    return isValid;
  }

  function submitInvoice() {
    setSubmitInvoiceClicked(true);
    switch (submitInvoiceType) {
      case RECEIPT_TYPES.grReceipt:
        resubmitOrder({ orderId: order?.id, invoiceType: InvoiceType.Retail });
        break;
      case RECEIPT_TYPES.openTable:
        resubmitOrder({ orderId: order?.id, invoiceType: InvoiceType.OpenTable });
        break;
      case RECEIPT_TYPES.helperWholesale:
        if (customerFieldsValidator()) {
          resubmitOrder({ orderId: order?.id, invoiceType: InvoiceType.HelperWholesale, customer: customerInputs });
        } else {
          setSubmitInvoiceClicked(false);
        }
        break;
      case RECEIPT_TYPES.wholesale:
        if (customerFieldsValidator()) {
          resubmitOrder({ orderId: order?.id, invoiceType: InvoiceType.Wholesale, customer: customerInputs });
        } else {
          setSubmitInvoiceClicked(false);
        }
        break;
      default:
    }
  }

  function handleCustomerInputChange(event) {
    setCustomerInputs((prevInputs) => {
      return {
        ...prevInputs,
        [event.target.name]: event.target.value,
      };
    });
  }

  function transformToPayedWithCard() {
    updateOrderStatus({
      orderId: order?.id,
      status: OrderStatus.Payed,
      paymentMethod: PaymentMethods.CARD.value,
      force_send_cloud_invoice: false,
      cancellationReason: null,
    }).then(() => {
      broadCastOrdersChangeWS(organisationId);
    });
  }
  function transformToPayedWithCash() {
    updateOrderStatus({
      orderId: order?.id,
      status: OrderStatus.Payed,
      paymentMethod: PaymentMethods.CASH.value,
      force_send_cloud_invoice: false,
      cancellationReason: null,
    }).then(() => {
      broadCastOrdersChangeWS(organisationId);
    });
  }

  let hasOrderDetailsWithInvoice = order?.orderDetails?.some((detail) => detail.parent_invoice?.data?.id);

  let submitInvoiceBtn = (
    <Button
      id="pay-items-btn-2"
      className="btn-icon ml-3 btn btn-primary col-4 col-md-6 col-lg-4"
      color="secondary"
      size="sm"
      disabled={
        submitInvoiceType.length === 0 ||
        submitRetailInvoiceDisabled ||
        helperwholesaleInvoiceEnabled ||
        submitInvoiceClicked
      }
      onClick={() => {
        submitInvoice();
      }}
    >
      <span className="btn-inner--icon">
        <i className="fas fa-cash-register" />
      </span>
      <span className="btn-inner--text">{t("Submit_invoice_short")}</span>
    </Button>
  );
  let customerSelector = (
    <div className="accordion col-12 col-md-6 mr-auto pl-0 pr-0 ml-0 mb-4 mt-3">
      <Card className="card-plain discount-dropdown-card mb-2" key={0}>
        <CardHeader
          className={`p-3`}
          role="tab"
          onClick={() => setCustomerAccordionOpen(!customerAccordionOpen)}
          aria-expanded={customerAccordionOpen}
        >
          <h5 className="mb-0">{t("Customer_info")}</h5>
        </CardHeader>
        <Collapse role="tabpanel" isOpen={customerAccordionOpen}>
          <CardBody className="p-3">
            <Row>
              <CustomerSearch
                customerInputs={customerInputs}
                setCustomerInputs={setCustomerInputs}
                initialName={customerInputs.name}
              />
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="name">
                    {t("AFM")}
                  </label>
                  <div className="position-relative">
                    <Input
                      className="form-control-alternative"
                      id=""
                      name="vat_number"
                      placeholder={t("AFM")}
                      type="text"
                      value={customerInputs.vat_number}
                      onChange={handleCustomerInputChange}
                      pattern="[0-9]*"
                    />
                    <SearchVatButton vatNumber={customerInputs.vat_number} setCustomerInputs={setCustomerInputs} />
                  </div>
                  <p className="text-danger text-sm">{customerInputs.errors.vat_number}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="doy">
                    {t("DOY")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="doy"
                    name="doy"
                    placeholder={t("DOY")}
                    type="text"
                    value={customerInputs.doy}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.doy}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="gemi_number">
                    {t("GEMH")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="gemi_number"
                    name="gemi_number"
                    placeholder={t("GEMH")}
                    type="text"
                    value={customerInputs.gemi_number}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.gemi_number}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="address">
                    {t("Address")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="address"
                    name="address"
                    placeholder={t("Address")}
                    type="text"
                    value={customerInputs.address}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.address}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="city">
                    {t("City")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="city"
                    name="city"
                    placeholder={t("City")}
                    type="text"
                    value={customerInputs.city}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.city}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="postcode">
                    {t("Postcode")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="postcode"
                    name="postcode"
                    placeholder={t("Postcode")}
                    type="text"
                    value={customerInputs.postcode}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.postcode}</p>
                </FormGroup>
              </Col>
              <Col xs="12" md="6" className="pl-2 pr-3">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="phone_number">
                    {t("Phone")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="phone_number"
                    name="phone_number"
                    placeholder={t("Phone")}
                    type="text"
                    value={customerInputs.phone_number}
                    onChange={handleCustomerInputChange}
                  />
                  <p className="text-danger text-sm">{customerInputs.errors.phone_number}</p>
                </FormGroup>
              </Col>
              <div
                className="accordion-footer w-100 mt-2 col-12 ml-auto d-flex justify-content-end"
                style={{ height: "46px" }}
              >
                {submitInvoiceBtn}
              </div>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
  let customerDisplay = (
    <div className="accordion col-12 col-md-6 mr-auto pl-0 pr-0 ml-0">
      <Card className="card-plain discount-dropdown-card mb-2" key={0}>
        <CardHeader
          className={`p-3`}
          role="tab"
          onClick={() => setCustomerAccordionOpen(!customerAccordionOpen)}
          aria-expanded={customerAccordionOpen}
        >
          <h5 className="mb-0">{t("Customer_info")}</h5>
        </CardHeader>
        <Collapse role="tabpanel" isOpen={customerAccordionOpen}>
          <CardBody className="pt-2 pr-3 pl-3 pb-2">
            <Row>
              <Col xs="12" md="6" className="pl-3 pr-2">
                <label className="form-control-label mr-2">{t("Business_name")}</label>
                <div className="form-control-value">{order?.customer?.name}</div>
              </Col>
              <Col xs="12" md="6" className="mt-2 pl-3 pr-2">
                <label className="form-control-label mr-2">{t("AFM")}</label>
                <div className="form-control-value">{order?.customer?.vat_number}</div>
              </Col>
              <Col xs="12" md="6" className="mt-2 pl-3 pr-2">
                <label className="form-control-label mr-2">{t("DOY")}</label>
                <div className="form-control-value">{order?.customer?.doy}</div>
              </Col>
              <Col xs="12" md="6" className="mt-2 pl-3 pr-2">
                <label className="form-control-label mr-2">{t("GEMH")}</label>
                <div className="form-control-value">{order?.customer?.gemi_number}</div>
              </Col>
              <Col xs="12" md="6" className="mt-2 pl-3 pr-2">
                <label className="form-control-label mr-2">{t("Address")}</label>
                <div className="form-control-value">{order?.customer?.address}</div>
              </Col>
              <Col xs="12" md="6" className="mt-2 pl-3 pr-2">
                <label className="form-control-label mr-2">{t("City")}</label>
                <div className="form-control-value">{order?.customer?.city}</div>
              </Col>
              <Col xs="12" md="6" className="mt-2 pl-3 pr-2">
                <label className="form-control-label mr-2">{t("Postcode")}</label>
                <div className="form-control-value">{order?.customer?.postcode}</div>
              </Col>
              <Col xs="12" md="6" className="mt-2 pl-3 pr-2">
                <label className="form-control-label mr-2">{t("Phone")}</label>
                <div className="form-control-value">{order?.customer?.phone_number}</div>
              </Col>
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );

  return (
    <>
      <AlertModal
        isOpen={props.isOpen}
        title={t(Views.VIEW_ORDER)}
        onCancel={props.onCancel}
        isLoading={isOrderFetching}
        size="lg"
        body={
          <div className="view-orders-view">
            <Row>
              <Col xs="12">
                <Row>
                  {order?.mydata_status === "sent_with_error" && (
                    <Col lg="12" className="d-flex mb-0">
                      <Alert
                        color="danger"
                        className="col-lg-8 d-flex flex-column flex-md-row align-items-md-center align-items-start justify-content-between p-3"
                      >
                        <span className="alert-text ml-1 mb-3 mb-md-0 mr-md-4">
                          <strong>{t("Invoice_submission_error")}: </strong> {order?.invoice_error_msg}
                        </span>
                        <Button
                          id="pay-items-btn-2"
                          className="btn-icon ml-0 btn btn-primary form-control-value"
                          color="secondary"
                          size="sm"
                          disabled={resubmitOrderLoading || submitInvoiceClicked}
                          onClick={() => {
                            resubmitOrder({
                              orderId: order?.id,
                              invoiceType: order?.invoice_type || InvoiceType.Retail,
                            });
                          }}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-redo" />
                          </span>
                          <span className="btn-inner--text">{t("Try_again")}</span>
                        </Button>
                      </Alert>
                    </Col>
                  )}

                  <Col lg="12" className="order-info d-flex mb-0">
                    <div className="mr-2">
                      {t("DateTime", {
                        date: formatDateCustomFormat(order?.dateSubmitted, locale, "D MMM YYYY HH:mm:ss"),
                      })}
                    </div>
                    <div> · ID: {order?.id}</div>
                  </Col>

                  <Col lg="12" className="mt-2">
                    <label className="form-control-label mr-2">{t("table")}</label>
                    <div className="form-control-value">{order?.table}</div>
                  </Col>
                  <Col lg="12" className="mt-2">
                    <label className="form-control-label mr-2">{t("User")}</label>
                    <div className="form-control-value">{order?.submittedBy}</div>
                  </Col>
                  {order?.external_source && (
                    <Col lg="12" className="mt-2">
                      <label className="form-control-label mr-2">{t("Order_type_external_src")}</label>
                      <div className="form-control-value">{order?.external_source}</div>
                    </Col>
                  )}
                  {discounts_enabled && (
                    <>
                      <Col lg="12" className="mt-2">
                        <label className="form-control-label mr-2">{t("Discount")}</label>
                        <div className="form-control-value">{order?.discount}</div>
                      </Col>
                      <Col lg="12" className="mt-2">
                        <label className="form-control-label mr-2">{t("Discount_reason")}</label>
                        <div className="form-control-value">{order?.discount_reason}</div>
                      </Col>
                    </>
                  )}
                  <Col lg="12" className="mt-2">
                    <label className="form-control-label mr-2">{t("Total")}</label>
                    <div className="form-control-value">{order?.totalPrice}</div>
                  </Col>
                  <Col lg="12" className="mt-2">
                    <label className="form-control-label mr-2">{t("status")}</label>
                    <div className="form-control-value">{t(capitalizeFirstLetter(orderStatus))}</div>
                  </Col>
                  {orderStatus?.toLowerCase() === OrderStatus.Cancelled && (
                    <Col lg="12" className="mt-2">
                      <label className="form-control-label mr-2">{t("Cancellation_reason")}</label>
                      <div className="form-control-value">{getCancellationReason(order)}</div>
                    </Col>
                  )}
                  <Col lg="12" className="mt-2">
                    <label className="form-control-label mr-2">{t("Comments")}</label>
                    <div className="form-control-value vieworder-comments">
                      {parse(order?.notes || t("No_comments"))}
                    </div>
                  </Col>
                  <Col lg="12" className="mt-2 d-flex">
                    <div className="div">
                      <label className="form-control-label mr-2">{t("Payment_method")}</label>
                      <div className="form-control-value">
                        {order?.payment_method ? t(capitalizeFirstLetter(order?.payment_method)) : ""}
                        {cloud_cash_register_enabled &&
                          pos_integration_enabled &&
                          (currentUserRole === "admin" || currentUserRole === "manager") &&
                          order?.payment_method !== PaymentMethods.CARD.value &&
                          (order?.status.toLowerCase() === OrderStatus.Payed ||
                            order?.status.toLowerCase() === OrderStatus.Cancelled) && (
                            <Button
                              className={`btn mt-1 ${order?.status.toLowerCase() === OrderStatus.Payed ? "ml-2" : ""}`}
                              color="primary"
                              size="sm"
                              disabled={updateOrderStatusLoading}
                              onClick={transformToPayedWithCard}
                            >
                              {t("Transform_pay_card")}
                            </Button>
                          )}
                        {cloud_cash_register_enabled &&
                          pos_integration_enabled &&
                          (currentUserRole === "admin" || currentUserRole === "manager") &&
                          (order?.status.toLowerCase() === OrderStatus.Cancelled ||
                            (order?.status.toLowerCase() === OrderStatus.Payed &&
                              order?.payment_method === PaymentMethods.CARD.value)) && (
                            <Button
                              className={`btn mt-1 ${order?.status.toLowerCase() === OrderStatus.Payed ? "ml-2" : ""}`}
                              color="primary"
                              outline
                              size="sm"
                              disabled={updateOrderStatusLoading}
                              onClick={transformToPayedWithCash}
                            >
                              {t("Transform_pay_cash")}
                            </Button>
                          )}
                      </div>
                    </div>
                  </Col>
                  {(cloud_cash_register_enabled || helper_wholesale_receipts_enabled) && (
                    <>
                      <Col lg="12" className="mt-2">
                        <label className="form-control-label mr-2">{t("Invoice_Type")}</label>
                        <div className="form-control-value">{t(order?.invoice_type)}</div>
                      </Col>
                      <Col lg="12" className="mt-2">
                        <label className="form-control-label mr-2">{t("Receipt_id")}</label>
                        <div className="form-control-value">{order?.receipt_id}</div>
                      </Col>
                      <Col lg="12" className="mt-2">
                        <label className="form-control-label mr-2">{t("Series")}</label>
                        <div className="form-control-value">{order?.invoice_series}</div>
                      </Col>
                    </>
                  )}
                  {cloud_cash_register_enabled && (
                    <>
                      <Col lg="12" className="mt-2">
                        <label className="form-control-label mr-2">Mark</label>
                        <div className="form-control-value">{order?.mydata_gr_mark}</div>
                      </Col>
                      <Col lg="12" className="mt-2">
                        <label className="form-control-label mr-2">UID</label>
                        <div className="form-control-value">{order?.mydata_gr_invoice_uuid}</div>
                      </Col>
                      <Col lg="12" className="mt-2">
                        <label className="form-control-label mr-2">Auth Code</label>
                        <div className="form-control-value">{order?.authcode}</div>
                      </Col>
                      {order?.mydata_gr_cancellation_mark && (
                        <>
                          <Col lg="12" className="mt-2">
                            <label className="form-control-label mr-2">{t("Cancel_receipt_id")}</label>
                            <div className="form-control-value">{order?.cancel_receipt_id}</div>
                          </Col>
                          <Col lg="12" className="mt-2">
                            <label className="form-control-label mr-2">{t("Cancellation_Mark")}</label>
                            <div className="form-control-value">{order?.mydata_gr_cancellation_mark}</div>
                          </Col>
                          <Col lg="12" className="mt-2">
                            <label className="form-control-label mr-2">{t("Cancellation_UID")}</label>
                            <div className="form-control-value">{order?.mydata_gr_cancellation_uid}</div>
                          </Col>
                          <Col lg="12" className="mt-2">
                            <label className="form-control-label mr-2">{t("Cancellation_Authcode")}</label>
                            <div className="form-control-value">{order?.cancellation_authcode}</div>
                          </Col>
                        </>
                      )}
                      <Col lg="12" className="mt-2">
                        <label className="form-control-label mr-2">{t("Mydata_status")}</label>
                        {!order?.mydata_gr_mark && hasOrderDetailsWithInvoice ? (
                          <div className="form-control-value">{t(`Invoice_from_parent_msg`)}</div>
                        ) : (
                          <div className="form-control-value">{t(`mydata_status_${order?.mydata_status}`)}</div>
                        )}
                      </Col>
                    </>
                  )}
                  <Col lg="12" className="mt-2 pl-2 ">
                    {order?.invoice_type === InvoiceType.HelperWholesale ||
                      (order?.invoice_type === InvoiceType.Wholesale && (
                        <div className="mt-2 ml-1">{customerDisplay}</div>
                      ))}
                  </Col>

                  {order?.id && (
                    <Col lg="12" className="mt-2">
                      <label className="form-control-label mr-2">{t("Reprint")}</label>

                      <div className="d-flex flex-direction-column col-12 col-md-8 col-lg-6 pl-0 mt-2">
                        <Input
                          className="col-8"
                          type="select"
                          name="select"
                          id="exampleSelect"
                          value={reprint_type}
                          onChange={(e) => setReprintType(e.target.value)}
                        >
                          <option value={RECEIPT_TYPES.internal}>{t("Internal")}</option>
                          <option value={RECEIPT_TYPES.openTable}>{t("OpenTable")}</option>
                          {order?.invoice_type === InvoiceType.OpenTable && (
                            <option value={RECEIPT_TYPES.openTableInvoice}>{t(InvoiceType.OpenTable)}</option>
                          )}
                          {order?.invoice_type === InvoiceType.HelperWholesale && (
                            <option value={InvoiceType.HelperWholesale}>{t(InvoiceType.HelperWholesale)}</option>
                          )}
                          {order?.invoice_type === InvoiceType.Wholesale &&
                            order?.mydata_gr_mark &&
                            order?.mydata_gr_invoice_uuid && (
                              <option value={InvoiceType.Wholesale}>{t(InvoiceType.Wholesale)}</option>
                            )}
                          {cloud_cash_register_enabled &&
                            order?.invoice_type === InvoiceType.Retail &&
                            order?.mydata_gr_mark &&
                            order?.mydata_gr_invoice_uuid && (
                              <option value={RECEIPT_TYPES.grReceipt}>{t("GR_Receipt")}</option>
                            )}
                        </Input>

                        <Button
                          id="pay-items-btn-2"
                          className="btn-icon ml-3 btn btn-primary col-4 col-md-6 col-lg-4"
                          color="secondary"
                          size="sm"
                          disabled={reprintOrderLoading}
                          onClick={() => {
                            reprintOrder({ reprint_type, order_id: order?.id }).then(() => {
                              //Todo: show success message
                            });
                          }}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-print" />
                          </span>
                          <span className="btn-inner--text">{t("Reprint")}</span>
                        </Button>
                      </div>
                    </Col>
                  )}
                  {/* <ul>
                    {order?.invoice_payment_methods?.map((method, methodIndex) => (
                      <li key={methodIndex}>{JSON.stringify(method)}</li>
                    ))}
                  </ul> */}
                  {(helper_wholesale_receipts_enabled ||
                    (cloud_cash_register_enabled &&
                      !order?.mydata_gr_mark &&
                      !order?.mydata_gr_invoice_uuid &&
                      !hasOrderDetailsWithInvoice)) && (
                    <Col lg="12" className="mt-2">
                      <label className="form-control-label mr-2">{t("Submit_invoice")}</label>

                      <div className="d-flex flex-direction-column col-12 col-md-8  col-lg-6 pl-0 mt-2">
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          value={submitInvoiceType}
                          onChange={(e) => setSubmitInvoiceType(e.target.value)}
                          className="col-8"
                        >
                          <option value="">{t("None")}</option>
                          {!submitRetailInvoiceDisabled && order?.status?.toLowerCase() === OrderStatus.Payed && (
                            <option value={RECEIPT_TYPES.grReceipt}>{t("GR_Receipt")}</option>
                          )}
                          {!submitRetailInvoiceDisabled && order?.status?.toLowerCase() === OrderStatus.Pending && (
                            <option value={RECEIPT_TYPES.openTable}>{t("OpenTable_invoice")}</option>
                          )}
                          {helper_wholesale_receipts_enabled && (
                            <option value={InvoiceType.HelperWholesale}>{t(InvoiceType.HelperWholesale)}</option>
                          )}
                          {wholesaleInvoiceEnabled && (
                            <option value={InvoiceType.Wholesale}>{t(InvoiceType.Wholesale)}</option>
                          )}
                        </Input>

                        {submitInvoiceBtn}
                      </div>
                      {((submitInvoiceType === InvoiceType.HelperWholesale && !helperwholesaleInvoiceEnabled) ||
                        (submitInvoiceType === InvoiceType.Wholesale && wholesaleInvoiceEnabled)) && (
                        <div className="mt-2 ml-1">{customerSelector}</div>
                      )}
                    </Col>
                  )}

                  <Col lg="12" className="mt-4">
                    <label className="form-control-label mr-2 mb-3">{t("Products")}</label>
                    <Table className="mb-0" columns={productsTableCols} data={orderDetails} pageSize={10} />
                  </Col>

                  {cancelGiftRuleEnabled && (
                    <Col lg="12" className="mt-4">
                      <FormGroup className="d-flex flex-wrap mt-2 mb-0">
                        <Button
                          id="pay-items-btn"
                          className="col-12 mb-2 col-md-3 btn btn-primary"
                          color="primary"
                          disabled={
                            (order?.status?.toLowerCase() !== OrderStatus.Pending &&
                              order?.status?.toLowerCase() !== OrderStatus.Payed) ||
                            bulkUpdateLoading ||
                            updateOrderStatusLoading
                          }
                          onClick={() => setCancellationModalOpen(true)}
                        >
                          {t("Cancel")}
                        </Button>
                        <Button
                          id="pay-items-btn"
                          className="col-12 mb-2 col-md-3 ml-0 btn btn-primary"
                          color="default"
                          outline
                          disabled={
                            (order?.status?.toLowerCase() !== OrderStatus.Pending &&
                              order?.status?.toLowerCase() !== OrderStatus.Payed) ||
                            updateOrderStatusLoading
                          }
                          onClick={() => setGiftModalOpen(true)}
                        >
                          {t("Gift")}
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        }
      ></AlertModal>
      <CancellationReasonModal
        isOpen={cancellationModalOpen}
        title={`${t("Cancel_order")} ${order?.id}`}
        okText={t("Submit")}
        cancelText={t("Discard")}
        onOk={cancelOrder}
        onCancel={() => setCancellationModalOpen(false)}
        showReturnInventory={canReturnInventory}
        okDisabled={bulkUpdateLoading || updateOrderStatusLoading}
      />
      <GiftConfirmationModal
        isOpen={giftModalOpen}
        title={`${t("Gift_order")} ${order?.id}`}
        okText={t("Gift")}
        cancelText={t("Cancel")}
        onCancel={() => setGiftModalOpen(false)}
        onOk={giftOrder}
        selectedOrderTotal={order?.totalPrice}
        okDisabled={updateOrderStatusLoading || bulkUpdateLoading}
      />
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payment_method_enabled: null,
  customers_per_order_enabled: null,
  discounts_enabled: null,
  cloud_cash_register_enabled: null,
  receipt_printing: "order_submittion",
  manual_cloud_cash_register_enabled: null,
  helper_wholesale_receipts_enabled: null,
  user_notifications_enabled: null,
  wholesale_enabled: null,
  inventory_management_disabled: null,
  print_scenarios_disabled: null,
  auto_invoice_payment_card: null,
  course_stages_enabled: null,
  pos_integration_enabled: null,
  external_source_types_enabled: null,
  use_analytic_functions_disabled: null,
  default_option_without_invoice: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setSettings: (state, action) => {
      const {
        payment_method_enabled = null,
        customers_per_order_enabled = null,
        discounts_enabled = null,
        cloud_cash_register_enabled = null,
        receipt_printing = null,
        manual_cloud_cash_register_enabled = null,
        helper_wholesale_receipts_enabled = null,
        user_notifications_enabled = null,
        wholesale_enabled = null,
        inventory_management_disabled = null,
        print_scenarios_disabled = null,
        auto_invoice_payment_card = null,
        course_stages_enabled = null,
        pos_integration_enabled = null,
        external_source_types_enabled = null,
        use_analytic_functions_disabled = null,
        default_option_without_invoice = null,
      } = action.payload || {};

      state.payment_method_enabled = payment_method_enabled;
      state.customers_per_order_enabled = customers_per_order_enabled;
      state.discounts_enabled = discounts_enabled;
      state.cloud_cash_register_enabled = cloud_cash_register_enabled;
      state.receipt_printing = receipt_printing;
      state.manual_cloud_cash_register_enabled = manual_cloud_cash_register_enabled;
      state.helper_wholesale_receipts_enabled = helper_wholesale_receipts_enabled;
      state.user_notifications_enabled = user_notifications_enabled;
      state.wholesale_enabled = wholesale_enabled;
      state.inventory_management_disabled = inventory_management_disabled;
      state.print_scenarios_disabled = print_scenarios_disabled;
      state.auto_invoice_payment_card = auto_invoice_payment_card;
      state.course_stages_enabled = course_stages_enabled;
      state.pos_integration_enabled = pos_integration_enabled;
      state.external_source_types_enabled = external_source_types_enabled;
      state.use_analytic_functions_disabled = use_analytic_functions_disabled;
      state.default_option_without_invoice = default_option_without_invoice;
    },
  },
});

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;

import React from "react";
import DefaultLayout from "templates/DefaultLayout";
import useVariations from "./useVariations";
import CrudTable from "components/Tables/CrudTable";
import { Button, Col, FormGroup, Input, Row, Table } from "reactstrap";
import AlertModal from "components/Modals/AlertModal";
import { Views } from "utilities/enums/Views";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { draggableDraggingStyles } from "utilities/constants";

function Variations() {
  const {
    variants,
    options,
    isLoading,
    isError,
    inputs,
    selectedVariant,
    addVariantDialogOpen,
    editVariantDialogOpen,
    deleteVariantDialogOpen,
    setInputs,
    handleAddVariantDialogOpen,
    handleAddVariantDialogClose,
    handleEditVariantDialogOpen,
    handleEditVariantDialogClose,
    handleDeleteVariantDialogOpen,
    handleDeleteVariantDialogClose,
    handleInputChange,
    handleAddVariantSubmit,
    handleUpdateVariantSubmit,
    handleDeleteVariantSubmit,
    handleDragEnd,
  } = useVariations();
  const { t } = useTranslation();

  const tableCols = [
    { label: "id", mobileVisible: false },
    { label: t("Name") },
    { label: "label", hidden: true },
    { label: "type", hidden: true },
    { label: t("Enabled"), mobileVisible: false },
    { label: t("Options"), mobileVisible: false },
    { label: t("Actions"), hidden: true },
  ];

  let header = (
    <Row>
      <Col xs="6">
        <h3 className="mb-0">{t(Views.VARIATIONS)}</h3>
      </Col>
      <Col xs="6" className="d-flex justify-content-end">
        <Button color="primary" size="sm" onClick={handleAddVariantDialogOpen}>
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-user-edit" />
          </span>
          <span className="btn-inner--text">{t("Add")}</span>
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      <DefaultLayout
        name={Views.VARIATIONS}
        parentName={Views.MENU_MANAGEMENT}
        isLoading={isLoading}
        isError={isError}
        header={header}
      >
        {variants?.length > 0 ? (
          <CrudTable columns={tableCols} data={variants} onEdit={handleEditVariantDialogOpen} pageSize={7} />
        ) : (
          <div className="text-center">{t("No_variations_available")}</div>
        )}
      </DefaultLayout>
      <AlertModal
        isOpen={addVariantDialogOpen}
        onCancel={handleAddVariantDialogClose}
        onOk={handleAddVariantSubmit}
        title={t("New_Variation")}
        okText={t("Add")}
        cancelText={t("Cancel")}
        body={
          <>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    {t("Name")}
                  </label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    onChange={handleInputChange}
                    value={inputs.name}
                  />
                  <p className="text-danger">{inputs.errors.name}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-label">
                    {t("Cust_label")}
                  </label>
                  <Input
                    type="text"
                    name="label"
                    id="label"
                    placeholder={t("Cust_label")}
                    onChange={handleInputChange}
                    value={inputs.label}
                  />
                  <p className="text-danger">{inputs.errors.label}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-type">
                    {t("Type")}
                  </label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    placeholder="Type"
                    onChange={handleInputChange}
                    value={inputs.type}
                  >
                    <option value="or">{t("Single")}</option>
                    <option value="and">{t("Multiple")}</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-enabled">
                    {t("Enabled")}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label className="custom-toggle custom-toggle-primary">
                    <input
                      type="checkbox"
                      name="enabled"
                      id="enabled"
                      onChange={(e) => setInputs({ ...inputs, enabled: e.target.checked })}
                      checked={inputs.enabled}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off={t("No")}
                      data-label-on={t("Yes")}
                    />
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Row>
                    <Col xs="6">
                      <label className="form-control-label" htmlFor="variant-options">
                        {t("Options")}
                      </label>
                      <Input
                        type="select"
                        name="selectedVOption"
                        id="variant-options"
                        placeholder={t("Select")}
                        onChange={handleInputChange}
                        value={inputs.selectedVOption}
                      >
                        <option value="">{t("Select")}</option>
                        {inputs.availableOptions &&
                          inputs.availableOptions.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                      </Input>
                    </Col>
                    <Col xs="6" className="d-flex align-items-end">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          if (inputs.selectedVOption !== "") {
                            setInputs({
                              ...inputs,
                              options: [...inputs.options, parseInt(inputs.selectedVOption)],
                              availableOptions: inputs.availableOptions.filter(
                                (option) => parseInt(option.id) !== parseInt(inputs.selectedVOption)
                              ),
                              selectedVOption: "",
                            });
                          }
                        }}
                      >
                        {t("Add")}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-options">
                    {t("Selected_options")}
                  </label>
                </FormGroup>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Table
                    styles={{
                      "table-layout": "fixed",
                    }}
                    id="table"
                  >
                    <thead>
                      <tr>
                        <th>{t("Name")}</th>
                        <th>{t("Cust_label")}</th>
                        <th>{t("price")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    <Droppable droppableId="table">
                      {(droppableProvided) => (
                        <tbody
                          ref={(ref) => {
                            droppableProvided.innerRef(ref);
                          }}
                          {...droppableProvided.droppableProps}
                        >
                          {options &&
                            inputs.options &&
                            inputs.options.map((option, i) => {
                              const selectedOption = options.filter((o) => parseInt(o.id) === parseInt(option))[0];
                              return (
                                selectedOption && (
                                  <Draggable
                                    draggableId={`${selectedOption.id}`}
                                    key={`${selectedOption.id}`}
                                    index={i}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <tr
                                          ref={provided.innerRef}
                                          isdragging={snapshot.isDragging.toString()}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            ...(snapshot.isDragging ? draggableDraggingStyles : {}),
                                          }}
                                        >
                                          <td>{selectedOption.name}</td>
                                          <td>{selectedOption.label}</td>
                                          <td>{selectedOption.price}</td>
                                          <td>
                                            <Button
                                              color="danger"
                                              size="sm"
                                              onClick={() => {
                                                setInputs({
                                                  ...inputs,
                                                  options: inputs.options.filter(
                                                    (o) => parseInt(o) !== parseInt(selectedOption.id)
                                                  ),
                                                  availableOptions: [...inputs.availableOptions, selectedOption],
                                                });
                                              }}
                                            >
                                              {t("Remove")}
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    }}
                                  </Draggable>
                                )
                              );
                            })}
                          {droppableProvided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              </Col>
            </Row>
          </>
        }
      />
      <AlertModal
        isOpen={editVariantDialogOpen}
        onCancel={handleEditVariantDialogClose}
        onOk={handleUpdateVariantSubmit}
        okText={t("Save")}
        cancelText={t("Cancel")}
        title={t("Edit_Variation")}
        body={
          <>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    {t("Name")}
                  </label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t("Name")}
                    onChange={handleInputChange}
                    value={inputs.name}
                  />
                  <p className="text-danger">{inputs.errors.name}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="label">
                    {t("Cust_label")}
                  </label>
                  <Input
                    type="text"
                    name="label"
                    id="label"
                    placeholder={t("Cust_label")}
                    onChange={handleInputChange}
                    value={inputs.label}
                  />
                  <p className="text-danger">{inputs.errors.label}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="type">
                    {t("Type")}
                  </label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    placeholder="Type"
                    onChange={handleInputChange}
                    value={inputs.type}
                  >
                    <option value="or">{t("Single")}</option>
                    <option value="and">{t("Multiple")}</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-enabled">
                    {t("Enabled")}
                  </label>
                </FormGroup>
                <FormGroup>
                  <label className="custom-toggle custom-toggle-primary">
                    <input
                      type="checkbox"
                      name="enabled"
                      id="enabled"
                      onChange={(e) => setInputs({ ...inputs, enabled: e.target.checked })}
                      checked={inputs.enabled}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off={t("No")}
                      data-label-on={t("Yes")}
                    />
                  </label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Row>
                    <Col xs="6">
                      <label className="form-control-label" htmlFor="variant-options">
                        {t("Options")}
                      </label>
                      <Input
                        type="select"
                        name="selectedVOption"
                        id="variant-options"
                        placeholder={t("Variation_Options")}
                        onChange={handleInputChange}
                        value={inputs.selectedVOption}
                      >
                        <option value="">{t("Variation_Options")}</option>
                        {inputs.availableOptions &&
                          inputs.availableOptions.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                      </Input>
                    </Col>
                    <Col xs="6" className="d-flex align-items-end">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          if (inputs.selectedVOption !== "") {
                            setInputs({
                              ...inputs,
                              options: [...inputs.options, parseInt(inputs.selectedVOption)],
                              availableOptions: inputs.availableOptions.filter(
                                (option) => parseInt(option.id) !== parseInt(inputs.selectedVOption)
                              ),
                              selectedVOption: "",
                            });
                          }
                        }}
                      >
                        {t("Add")}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Table
                    styles={{
                      "table-layout": "fixed",
                    }}
                    id="table"
                  >
                    <thead>
                      <tr>
                        <th>{t("Name")}</th>
                        <th>{t("Cust_label")}</th>
                        <th>{t("price")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    <Droppable droppableId="table">
                      {(droppableProvided) => (
                        <tbody
                          ref={(ref) => {
                            droppableProvided.innerRef(ref);
                          }}
                          {...droppableProvided.droppableProps}
                        >
                          {options &&
                            inputs.options &&
                            inputs.options.map((option, i) => {
                              const selectedOption = options.filter((o) => parseInt(o.id) === parseInt(option))[0];
                              return (
                                selectedOption && (
                                  <Draggable
                                    draggableId={`${selectedOption.id}`}
                                    key={`${selectedOption.id}`}
                                    index={i}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <tr
                                          ref={provided.innerRef}
                                          isdragging={snapshot.isDragging.toString()}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            ...(snapshot.isDragging ? draggableDraggingStyles : {}),
                                          }}
                                        >
                                          <td>{selectedOption.name}</td>
                                          <td>{selectedOption.label}</td>
                                          <td>{selectedOption.price}</td>
                                          <td>
                                            <Button
                                              color="danger"
                                              size="sm"
                                              onClick={() => {
                                                setInputs({
                                                  ...inputs,
                                                  options: inputs.options.filter(
                                                    (o) => parseInt(o) !== parseInt(selectedOption.id)
                                                  ),
                                                  availableOptions: [...inputs.availableOptions, selectedOption],
                                                });
                                              }}
                                            >
                                              {t("Remove")}
                                            </Button>
                                          </td>
                                        </tr>
                                      );
                                    }}
                                  </Draggable>
                                )
                              );
                            })}
                          {droppableProvided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              </Col>
            </Row>
          </>
        }
      />
      <AlertModal
        isOpen={deleteVariantDialogOpen}
        title={`${t("Delete_variation_msg")} "${selectedVariant.name}"?`}
        onCancel={handleDeleteVariantDialogClose}
        onOk={handleDeleteVariantSubmit}
        okText={t("Delete")}
        cancelText={t("Cancel")}
      />
    </>
  );
}

export default Variations;

import React from "react";
import { Row, Button, Col, FormGroup, Input, Table } from "reactstrap";
import MediaManager from "components/MediaManager/MediaManager";
import { VatCategory } from "utilities/enums/VatCategory";
import { useTranslation } from "react-i18next";
import AlertModal from "components/Modals/AlertModal";
import useNewProduct from "./useNewProduct";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { draggableDraggingStyles } from "utilities/constants";

function NewProduct(props) {
  const {
    inputs,
    variants,
    categories,
    setInputs,
    handleAddProductSubmit,
    handleInputChange,
    selectImageDialog,
    setSelectImageDialog,
    addProductLoading,
    handleDragEnd,
    inventory_management_disabled,
  } = useNewProduct(props);
  const { t } = useTranslation();

  return (
    <>
      <AlertModal
        isOpen={props.isOpen}
        title={t("New_Product")}
        okText={t("Add")}
        cancelText={t("Cancel")}
        onCancel={props?.onCancel}
        onOk={handleAddProductSubmit}
        okDisabled={addProductLoading}
        body={
          <>
            <Row>
              <Col xs="12">
                <FormGroup className="mb-0">
                  <label className="form-control-label" htmlFor="input-enabled">
                    {t("Enabled")}
                  </label>
                </FormGroup>
                <FormGroup className="mb-3">
                  <label className="custom-toggle mr-1">
                    <input
                      name="active"
                      type="checkbox"
                      checked={inputs.active}
                      onChange={(e) => setInputs({ ...inputs, active: e.target.checked })}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off={t("No")}
                      data-label-on={t("Yes")}
                    />
                  </label>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    {t("Name")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="name"
                    name="name"
                    placeholder={t("Name")}
                    type="text"
                    value={inputs.name}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.name}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="mb-4">
                <FormGroup className="mb-2">
                  <label className="form-control-label" htmlFor="name">
                    {t("Rank_label")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="rank"
                    name="rank"
                    min="1"
                    placeholder={t("Rank_label")}
                    type="number"
                    value={inputs.rank ? inputs.rank : ""}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger m-0">{inputs.errors.rank}</p>
                </FormGroup>
                <div className="hint">{t("Rank_product_field_hint")}</div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="description">
                    {t("Description")}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="description"
                    name="description"
                    placeholder={t("Description")}
                    type="textarea"
                    value={inputs.description ? inputs.description : ""}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col xs="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="category">
                    {t("Category")}
                  </label>
                  <Input
                    type="select"
                    name="category"
                    id="category"
                    onChange={handleInputChange}
                    value={inputs.category}
                    bsSize="sm"
                  >
                    <option value="">{t("Choose_category")}</option>
                    {categories &&
                      categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </Input>
                  <p className="text-danger">{inputs.errors.category}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="vat">
                    {t("VAT")}
                  </label>
                  <Input
                    type="select"
                    name="vat_category"
                    id="vat"
                    onChange={handleInputChange}
                    value={inputs.vat_category}
                    bsSize="sm"
                  >
                    {Object.entries(VatCategory).map((vat_category) => (
                      <option key={vat_category[0]} value={vat_category[1].value}>
                        {vat_category[1].label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="price">
                    {`${t("price")} (${t("Gross")})`}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="price"
                    name="price"
                    placeholder={t("price")}
                    type="number"
                    invalid={inputs.price < 0}
                    value={inputs.price}
                    onChange={handleInputChange}
                  />
                  <p className="text-danger">{inputs.errors.price}</p>
                </FormGroup>
              </Col>
            </Row>
            {!inventory_management_disabled && (
              <Row>
                <Col xs="12" className="mb-3">
                  <div className="custom-control custom-checkbox d-inline">
                    <Input
                      className="custom-control-input"
                      type="checkbox"
                      name="inventoryEnabled"
                      id="inventoryEnabled"
                      onChange={(e) => {
                        setInputs({ ...inputs, inventoryEnabled: e.target.checked });
                      }}
                      checked={inputs.inventoryEnabled}
                      bsSize="sm"
                    />
                    <label className="custom-control-label" htmlFor="inventoryEnabled">
                      {t("Inventory_management")}
                    </label>
                  </div>
                </Col>
                <Col xs="12" className={inputs.inventoryEnabled ? "" : "d-none"}>
                  <Col xs="12" className="pl-0">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="inventory">
                        {t("Quantity")}
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="inventoryQuantity"
                        name="inventoryQuantity"
                        placeholder={t("Quantity")}
                        type="number"
                        invalid={inputs.inventoryQuantity < 0}
                        value={inputs.inventoryQuantity}
                        onChange={handleInputChange}
                      />
                      <p className="text-danger">{inputs.errors.inventoryQuantity}</p>
                    </FormGroup>
                  </Col>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs="12">
                <FormGroup>
                  <Row className="align-items-center">
                    <Col xs="6">
                      <label className="form-control-label" htmlFor="selectVariation">
                        {t("Variation")}
                      </label>
                      <Input
                        type="select"
                        name="selectVariation"
                        id="selectVariation"
                        onChange={handleInputChange}
                        value={inputs.selectVariation}
                        bsSize="sm"
                      >
                        <option value="">{t("Select_variation")}</option>
                        {inputs.avialableVariants &&
                          inputs.avialableVariants.map((variant) => {
                            return (
                              <option key={variant.id} value={variant.id}>
                                {variant.name}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col xs="6" className="mt-auto">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => {
                          if (inputs.selectVariation !== "") {
                            setInputs({
                              ...inputs,
                              variants: [...inputs.variants, parseInt(inputs.selectVariation)],
                              avialableVariants: inputs.avialableVariants.filter(
                                (variant) => parseInt(variant.id) !== parseInt(inputs.selectVariation)
                              ),
                              selectVariation: "",
                            });
                          }
                        }}
                      >
                        {t("Add")}
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Table
                    styles={{
                      "table-layout": "fixed",
                    }}
                    id="table"
                    className="align-items-center table-flush"
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">id</th>
                        <th scope="col">{t("Name")}</th>
                        <th scope="col">{t("Cust_label")}</th>
                        <th scope="col">{t("Enabled")}</th>
                        <th scope="col">{t("")}</th>
                      </tr>
                    </thead>
                    <Droppable droppableId="table">
                      {(droppableProvided) => (
                        <tbody
                          ref={(ref) => {
                            droppableProvided.innerRef(ref);
                          }}
                          {...droppableProvided.droppableProps}
                        >
                          {inputs.variants &&
                            variants &&
                            inputs.variants.map((variantID, i) => {
                              const variant = variants.find((v) => parseInt(v.id) === parseInt(variantID));
                              return (
                                <Draggable draggableId={`${variantID}`} key={`${variantID}`} index={i}>
                                  {(provided, snapshot) => {
                                    return (
                                      <tr
                                        ref={provided.innerRef}
                                        isdragging={snapshot.isDragging.toString()}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          ...(snapshot.isDragging ? draggableDraggingStyles : {}),
                                        }}
                                      >
                                        <td>{variant.id}</td>
                                        <td>{variant.name}</td>
                                        <td>{variant.label}</td>
                                        <td>{variant.enabled ? t("Yes") : t("No")}</td>
                                        <td>
                                          <Button
                                            color="danger"
                                            size="sm"
                                            onClick={() => {
                                              setInputs((prevInputs) => {
                                                return {
                                                  ...prevInputs,
                                                  variants: inputs.variants.filter((v) => v !== variant.id),
                                                  avialableVariants: [...prevInputs.avialableVariants, variant],
                                                };
                                              });
                                            }}
                                          >
                                            {t("Remove")}
                                          </Button>
                                        </td>
                                      </tr>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                          {droppableProvided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              </Col>
            </Row>
          </>
        }
      ></AlertModal>
      <MediaManager
        isOpen={selectImageDialog}
        onCancel={() => {
          setSelectImageDialog(false);
        }}
        onOk={() => {
          setSelectImageDialog(false);
        }}
        selectedImage={inputs.image}
        setSelectedImage={(image) => {
          setInputs({ ...inputs, image: image });
        }}
      />
    </>
  );
}

export default NewProduct;
